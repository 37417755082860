import { put, call, select } from 'redux-saga/effects';
import { saveTimetracker as saveTimetrackerApi } from 'services/api/participant';
import { ParticipantActions, ParticipantSelectors } from 'store/participant';
import Swal from 'sweetalert2';
import {
  RtmSelectors as Selectors,
  RtmActions as Actions,
  RtmTypes as Types,
} from './rtmReducer';

function* saveTimeTracking(action) {
  yield put(Actions.setSaveTimeTrackingLoading(true));
  const currentParticipantId = yield select(Selectors.getCurrentParticipantId);
  const participant = yield select(ParticipantSelectors.getParticipant);

  const { time, note, noteType, startDate } = action;

  try {
    yield call(saveTimetrackerApi, {
      user_id: currentParticipantId,
      start_date: startDate,
      time,
      note,
      type_id: noteType,
    });

    Swal.fire({
      title: 'Saved',
    });

    if (participant?.id === currentParticipantId) {
      yield put(ParticipantActions.fetchParticipant(currentParticipantId));
    }
  } catch (error) {
    Swal.showValidationMessage(`Request failed: ${error}`);
    yield put(
      Actions.setRtmErrors({
        error,
        message: '[saveTimetracker Error] problem getting data',
      })
    );
  } finally {
    yield put(Actions.setSaveTimeTrackingLoading(false));
  }
}

export default () => ({
  [Types.SAVE_TIME_TRACKING]: saveTimeTracking,
});
