import React, { Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import store from 'store';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import useTheme from 'hooks/theme/useTheme';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import {
  auth0Domain,
  auth0ClientId,
  auth0Audience,
  auth0Callback,
} from './config';

const App = React.lazy(() => import('./App'));

const domain = auth0Domain || '';
const clientId = auth0ClientId || '';

function isLocalHost(url) {
  return url.indexOf('localhost') !== -1 || url.indexOf('127.0.0.1') !== -1;
}

/* eslint-disable  */
(function (apiKey) {
  (function (p, e, n, d, o) {
    var v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = o._q || [];
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? 'unshift' : 'push'](
              [m].concat([].slice.call(arguments, 0))
            );
          };
      })(v[w]);
    y = e.createElement(n);
    y.async = !0;
    y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, 'script', 'pendo');
})('666541b8-a374-4c21-58cb-0964bca0ff08');
/* eslint-enable  */

// eslint-disable-next-line no-restricted-globals
if (!isLocalHost(location.hostname)) {
  Sentry.init({
    dsn: 'https://daaaef60c2b74a1b8a6a3f463c341e91@o1258962.ingest.sentry.io/6463282',
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

const Main = () => {
  const theme = useTheme();
  const [appState, setAppState] = useState(null);

  return (
    <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <Suspense fallback={<div className="loading" />}>
            <Auth0Provider
              domain={domain}
              clientId={clientId}
              audience={auth0Audience}
              redirectUri={auth0Callback}
              onRedirectCallback={(appState) => setAppState(appState)}
            >
              <App appState={appState} />
            </Auth0Provider>
          </Suspense>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
    </BrowserRouter>
  );
};

ReactDOM.render(<Main className="noprint" />, document.getElementById('root'));

reportWebVitals();
