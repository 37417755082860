import { put, call, select } from 'redux-saga/effects';
import {
  getParticipantFlagsList,
  resolveParticipantFlagById,
} from 'services/api/participant';
import { UserRole } from 'constants/defaultValues';
import { UserSelectors } from 'store/user';

import {
  ParticipantFlagsActions as Actions,
  ParticipantFlagsTypes as Types,
} from './participantFlagsReducer';

function* fetchParticipantFlags(action) {
  const { id } = action;
  const role = yield select(UserSelectors.getUserRole);

  yield put(Actions.setParticipantFlagsLoading(true));

  try {
    if (role.role_id !== UserRole.CareNavigator) {
      throw new Error('Only Care Navigators can view Participant Flags');
    }

    const response = yield call(getParticipantFlagsList, { id });
    yield put(Actions.fetchParticipantFlagsSuccess(response));
  } catch (error) {
    yield put(
      Actions.fetchParticipantFlagsError(`[fetchParticipants error]: ${error}`)
    );
  } finally {
    yield put(Actions.setParticipantFlagsLoading(false));
  }
}

function* resolveParticipantFlag(action) {
  const { participantId, flagId } = action;
  yield put(Actions.setIsResolvingFlag(true));
  try {
    const didUpdate = yield call(resolveParticipantFlagById, { id: flagId });
    if (didUpdate) {
      yield put(Actions.fetchParticipantFlags(participantId));
    }
  } catch (error) {
    yield put(Actions.setResolveFlagError(error));
  } finally {
    yield put(Actions.setIsResolvingFlag(false));
  }
}

export default () => ({
  [Types.FETCH_PARTICIPANT_FLAGS]: fetchParticipantFlags,
  [Types.RESOLVE_PARTICIPANT_FLAG]: resolveParticipantFlag,
});
