import { put, call } from 'redux-saga/effects';
import initPendo from 'helpers/pendoHelper';
import {
  createUser,
  deactivateParticipant,
  getParticipantsPaginated,
} from 'services/api/participant';
import { changeUserPassword } from 'services/api/user';
import { getOrganizations } from 'services/api/assessment';
import { getRole } from 'services/api/generic';
import { updateLastLogin } from 'services/api/provider';
import { FeatureFlagsActions } from 'store/feature-flags';
import Swal from 'sweetalert2';
import { UserActions as Actions, UserTypes as Types } from './userReducer';

function* setChangePassword({ participantId, password }) {
  yield put(Actions.setChangePasswordLoading(true));

  try {
    yield call(changeUserPassword, participantId, password);

    Swal.fire({
      title: 'Success',
      text: 'Password change correctly',
      icon: 'success',
    });
    yield put(Actions.setChangePasswordOk(true));
  } catch (error) {
    Swal.fire({
      title: 'Error',
      text: 'Failed to change password, try again later or contact an administrator',
      icon: 'error',
    });
    yield put(
      Actions.setUserErrors({
        error,
        message: '[changeUserPassword]: error setting the password',
      })
    );
  } finally {
    yield put(Actions.setChangePasswordLoading(false));
  }
}

function* setCreateUser({ values }) {
  yield put(Actions.setCreateUserLoading(true));

  try {
    yield call(createUser, values);
    yield put(Actions.setCreateUserOk(true));

    Swal.fire({
      title: 'Saved',
      icon: 'warning',
    });
  } catch (err) {
    const code =
      err.response.data?.message || err.response.data?.error?.message || '';
    let text;

    if (code === 'Error: User aleady exist in dictionary') {
      text =
        'Error External Id for this user already exists in this organization';
    } else if (code === 'User Email already in use') {
      text = 'This email is already in use';
    } else {
      text = "Error couldn't create user, try again later";
    }

    Swal.fire({
      title: 'Error',
      text,
      icon: 'info',
    });

    yield put(
      Actions.setUserErrors({
        error: err,
        message: '[createUser]: Failed to save new user',
      })
    );
  } finally {
    yield put(Actions.setCreateUserLoading(false));
  }
}

function* fetchOrganizations() {
  try {
    yield put(Actions.setOrganizationsLoading(true));
    const response = yield call(getOrganizations);

    yield put(Actions.fetchOrganizationsSuccess(response));
  } catch (error) {
    yield put(
      Actions.setUserErrors({
        error,
        message: '[createUser]: Failed to save new user',
      })
    );
    yield put(Actions.fetchOrganizationsSuccess(null));
  } finally {
    yield put(Actions.setOrganizationsLoading(false));
  }
}

export function* setUpdateLastLogin(userId) {
  try {
    yield call(updateLastLogin, userId);
  } catch (error) {
    yield put(
      Actions.setUserErrors({
        error,
        message: '[updateLastLogin]: problem updating last login',
      })
    );
  }
}

function* setLastLogin(action) {
  const { userId } = action;

  try {
    yield call(setUpdateLastLogin, userId);
  } catch (error) {
    yield put(
      Actions.setUserErrors({
        error,
        message: '[setUpdateLastLogin]: problem getting data',
      })
    );
  }
}

let userRoleController;
function* fetchUserRole(action) {
  if (userRoleController) {
    userRoleController.abort();
  }

  const { authUser } = action;
  yield put(Actions.setUserRoleIsLoading(true));

  try {
    userRoleController = new AbortController();
    const response = yield call(
      getRole,
      authUser.token,
      userRoleController.signal
    );
    yield put(Actions.fetchUserRoleSuccess(response[0]));
    yield put(Actions.setLastLogin(response[0].user_id));
    yield put(FeatureFlagsActions.fetchFeatureFlags());

    const { email, email_verified: emailVerified, sub } = authUser;

    const data = {
      email,
      emailVerified,
      fullName: response[0]?.full_name,
      authId: sub,
      id: response[0].user_id,
      role: response[0].name,
      roleId: response[0].role_id,
    };

    const account = {
      id: response[0]?.organization?.name,
    };

    initPendo(data, account);
  } catch (error) {
    yield put(
      Actions.setUserErrors({
        error,
        message: '[getUserRole]: problem getting data',
        unauthorized: true,
      })
    );
  } finally {
    yield put(Actions.setUserRoleIsLoading(false));
  }
}

function* deactivateUser({ userId }) {
  try {
    const response = yield call(deactivateParticipant, userId);

    yield put(Actions.setDeactivateUserSuccess(response));
  } catch (error) {
    Swal.showValidationMessage('Error occurred during the deactivation');
  }
}

function* fetchUsers({ filters = {} }) {
  try {
    yield put(Actions.setUsersIsLoading(true));
    const response = yield call(getParticipantsPaginated, filters);

    yield put(Actions.fetchUsersSuccess(response));
  } catch (error) {
    yield put(Actions.fetchUsersSuccess(null));
  } finally {
    yield put(Actions.setUsersIsLoading(false));
  }
}

export default () => ({
  [Types.FETCH_ORGANIZATIONS]: fetchOrganizations,
  [Types.SET_CHANGE_PASSWORD]: setChangePassword,
  [Types.SET_CREATE_USER]: setCreateUser,
  [Types.FETCH_USER_ROLE]: fetchUserRole,
  [Types.SET_LAST_LOGIN]: setLastLogin,
  [Types.SET_DEACTIVATE_USER]: deactivateUser,
  [Types.FETCH_USERS]: fetchUsers,
});
