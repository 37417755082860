import { put, call, all, select } from 'redux-saga/effects';
import Swal from 'sweetalert2';
import Immutable from 'seamless-immutable';
import {
  getGroups,
  getTags,
  getStatusOptions,
  getProviders,
  getOrganizations,
  getParticipantsList,
} from 'services/api/participant';
import {
  createUserFiltersView,
  deleteUserFiltersView,
  getUserFiltersView,
} from 'services/api/user';
import {
  filters as filtersKeys,
  sections,
} from 'constants/participantsFilters';
import { UserRole } from 'constants/defaultValues';
import { UserSelectors } from 'store/user';
import { filterRoots, viewsDefaults } from 'constants/filterEnums';
import {
  flatSelectedElementsToIds,
  getSelectedElementsOnList,
  removeAllSelectedElements,
} from 'helpers/filtersHelpers';
import { sortOrganizations } from 'helpers/organizationsHelpers';
import {
  ParticipantsActions as Actions,
  ParticipantsTypes as Types,
  ParticipantsSelectors as Selectors,
} from './participantsReducer';

let participantsListController;

function* fetchParticipants(action) {
  if (participantsListController) {
    participantsListController.abort();
  }

  const navigation = yield select(Selectors.getNavigation);
  const role = yield select(UserSelectors.getUserRole);
  const sort = yield select(Selectors.getOrdering);
  const { filters, search } = action;
  yield put(Actions.setParticipantsLoading(true));

  try {
    participantsListController = new AbortController();

    if (
      (search && search.length > 0) ||
      (role.role_id !== UserRole.Provider &&
        role.role_id !== UserRole.CareNavigator)
    ) {
      const response = yield call(getParticipantsList, {
        search,
        page: navigation.currentPage,
        limit: navigation.limit,
        unassigned: false,
        sort,
        categories: flatSelectedElementsToIds(filters[filtersKeys.categories]),
        status: filters[filtersKeys.status]?.value || null,
        signal: participantsListController.signal,
      });

      yield put(
        Actions.setNavigation({
          currentPage: response.currentPage,
          totalPages: response.pages,
        })
      );
      yield put(
        Actions.fetchParticipantsSuccess(response.participants, search)
      );
    } else {
      const response = yield call(getParticipantsList, {
        search,
        orgIds: flatSelectedElementsToIds(filters[filtersKeys.organizations]),
        groupIds: flatSelectedElementsToIds(filters[filtersKeys.groups]),
        providerIds: flatSelectedElementsToIds(filters[filtersKeys.providers]),
        page: navigation.currentPage,
        limit: navigation.limit,
        unassigned: false,
        sort,
        categories: flatSelectedElementsToIds(filters[filtersKeys.categories]),
        status: filters[filtersKeys.status]?.value || null,
        signal: participantsListController.signal,
      });

      yield put(
        Actions.setNavigation({
          currentPage: response.currentPage,
          totalPages: response.pages,
        })
      );
      yield put(
        Actions.fetchParticipantsSuccess(
          response.participants,
          JSON.stringify(filters)
        )
      );
    }
  } catch (error) {
    yield put(Actions.fetchParticipantsSuccess([], ''));
    yield put(
      Actions.setParticipantsErrors(`[fetchParticipants error]: ${error}`)
    );
  } finally {
    yield put(Actions.setParticipantsLoading(false));
  }
}

export function* fetchStatus() {
  yield put(Actions.setFiltersLoading({ [filtersKeys.status]: true }));
  try {
    const response = yield call(getStatusOptions, true);

    const status = response.map((item) => ({
      value: item.id,
      label: item.status,
      key: `status-${item.id}`,
    }));

    status.unshift({
      value: 0,
      label: 'Any status',
      key: `status-0`,
    });

    yield put(Actions.fetchFiltersSuccess({ [filtersKeys.status]: status }));
  } catch (error) {
    yield put(Actions.setParticipantsErrors(`[fetchStatus error]: ${error}`));
  } finally {
    yield put(Actions.setFiltersLoading({ [filtersKeys.status]: false }));
  }
}

export function* fetchCategories() {
  yield put(Actions.setFiltersLoading({ [filtersKeys.categories]: true }));
  try {
    const response = yield call(getTags, {
      categories: '[2]',
    });

    const categories = response
      .filter((item) => item.tag_category.id === 2 && item.id !== 6)
      .map((item) => ({
        value: item.id,
        label: item.slug,
        key: `categories-${item.id}`,
      }));

    yield put(
      Actions.fetchFiltersSuccess({
        [filtersKeys.categories]: categories,
      })
    );
    yield put(Actions.setFiltersLoading({ [filtersKeys.categories]: false }));
  } catch (error) {
    yield put(
      Actions.setParticipantsErrors(`[fetchCategories error]: ${error}`)
    );
    yield put(Actions.setFiltersLoading({ [filtersKeys.categories]: false }));
  }
}

let groupsController;
export function* fetchGroups(value, from = filterRoots.fetchGroups) {
  if (groupsController) {
    groupsController.abort();
  }
  const selectedFilters = yield select(Selectors.getSelectedFilters);
  yield put(Actions.setFiltersLoading({ [filtersKeys.groups]: true }));
  yield put(Actions.setFiltersLoading({ [filtersKeys.providers]: true }));
  try {
    groupsController = new AbortController();
    const response = yield call(getGroups, value, groupsController.signal);
    const groups = response.map((item) => ({
      value: item.id,
      label: item.name,
      key: `groups-${item.id}`,
    }));

    const selectedIncludes = getSelectedElementsOnList(
      groups,
      selectedFilters[filtersKeys.groups]
    );

    if (
      selectedFilters[filtersKeys.groups].length > 0 &&
      selectedIncludes.length === 0
    ) {
      yield put(
        Actions.setSelectedFiltersSuccess({ [filtersKeys.groups]: [] })
      );
    }

    if (selectedFilters[filtersKeys.groups].length > 0) {
      yield put(
        Actions.setSelectedFilters(
          {
            [filtersKeys.groups]: selectedIncludes,
          },
          null,
          from
        )
      );
    }

    yield put(
      Actions.fetchFiltersSuccess({
        [filtersKeys.groups]: sortOrganizations(groups),
      })
    );
  } catch (error) {
    yield put(Actions.setParticipantsErrors(`[fetchGroups error]: ${error}`));
  } finally {
    yield put(Actions.setFiltersLoading({ [filtersKeys.groups]: false }));
  }
}

let providerController;
function* fetchProviders(action) {
  if (providerController) {
    providerController.abort();
  }
  const selectedFilters = yield select(Selectors.getSelectedFilters);
  const { groupIds, orgIds, from } = action;
  const search = yield select(Selectors.getSearch);

  yield put(Actions.setFiltersLoading({ [filtersKeys.providers]: true }));
  try {
    providerController = new AbortController();
    const response = yield call(
      getProviders,
      groupIds,
      orgIds,
      providerController.signal
    );

    const providers = response.map((item) => ({
      value: item.user_id,
      label: `${item.first_name} ${item.last_name}`,
      key: `providers-${item.user_id}`,
    }));

    const selectedIsOnProviders = getSelectedElementsOnList(
      providers,
      selectedFilters[filtersKeys.providers]
    );

    if (
      selectedFilters[filtersKeys.providers].length > 0 &&
      selectedIsOnProviders.length === 0
    ) {
      yield put(
        Actions.setSelectedFiltersSuccess({ [filtersKeys.providers]: [] })
      );
    }

    if (selectedIsOnProviders.length > 0 && from !== filterRoots.search) {
      yield put(
        Actions.setSelectedFilters(
          {
            [filtersKeys.providers]: selectedIsOnProviders,
          },
          null,
          filterRoots.fetchProviders
        )
      );
      yield put(
        Actions.fetchFiltersSuccess({
          [filtersKeys.providers]: providers,
        })
      );
      yield put(Actions.setFiltersLoading({ [filtersKeys.providers]: false }));
      return;
    }

    if (
      providers.length === 0 ||
      selectedIsOnProviders.length === 0 ||
      search?.length > 0
    ) {
      yield put(
        Actions.setSelectedFilters(
          {
            [filtersKeys.providers]: [],
          },
          search,
          filterRoots.fetchProviders
        )
      );
    }

    yield put(
      Actions.fetchFiltersSuccess({
        [filtersKeys.providers]: providers,
      })
    );
  } catch (error) {
    yield put(
      Actions.setParticipantsErrors(`[fetchProviders error]: ${error}`)
    );
  } finally {
    yield put(Actions.setFiltersLoading({ [filtersKeys.providers]: false }));
  }
}

export function* fetchOrganizations(from = filterRoots.fetchOrganizations) {
  const selectedFilters = yield select(Selectors.getSelectedFilters);
  yield put(Actions.setFiltersLoading({ [filtersKeys.organizations]: true }));
  try {
    const response = yield call(getOrganizations);
    const organizations = response.map((item) => ({
      value: item.id,
      label: item.name,
      key: `organizations-${item.user_id}`,
    }));

    if (organizations.length === 1) {
      yield put(
        Actions.setSelectedFilters(
          {
            [filtersKeys.organizations]: organizations,
          },
          null,
          from
        )
      );

      // TODO: check why this needs to be dispatch here to set first time on select one.
      yield put(
        Actions.setSelectedFiltersSuccess({
          [filtersKeys.organizations]: organizations,
        })
      );
    }

    const selectedIncludes = getSelectedElementsOnList(
      organizations,
      selectedFilters[filtersKeys.organizations]
    );

    if (selectedFilters[filtersKeys.organizations].length > 0) {
      yield put(
        Actions.setSelectedFilters(
          {
            [filtersKeys.organizations]: selectedIncludes,
          },
          null,
          from
        )
      );
    }

    yield put(
      Actions.fetchFiltersSuccess({
        [filtersKeys.organizations]: sortOrganizations(organizations),
      })
    );
  } catch (error) {
    yield put(
      Actions.setParticipantsErrors(`[fetchOrganizations error]: ${error}`)
    );
  } finally {
    yield put(
      Actions.setFiltersLoading({ [filtersKeys.organizations]: false })
    );
  }
}

function* fetchFilters() {
  const role = yield select(UserSelectors.getUserRole);
  let defaultFilters = {
    [filtersKeys.organizations]: [],
    [filtersKeys.groups]: [],
    [filtersKeys.categories]: [],
    [filtersKeys.status]: null,
  };

  if (role.role_id === UserRole.Provider) {
    const currentProvider = {
      value: role.user_id,
      key: `providers-${role.user_id}`,
    };

    defaultFilters = {
      ...defaultFilters,
      [filtersKeys.providers]: [currentProvider],
    };
  }

  yield put(Actions.setPrevParams(''));
  yield put(
    Actions.setSelectedFiltersSuccess(
      {
        ...defaultFilters,
      },
      null
    )
  );

  try {
    yield put(
      Actions.setFiltersLoading({
        [filtersKeys.groups]: true,
        [filtersKeys.categories]: true,
        [filtersKeys.providers]: true,
        [filtersKeys.organizations]: true,
        [filtersKeys.status]: true,
      })
    );
    const calls = [call(fetchStatus), call(fetchCategories)];

    if (
      role.role_id === UserRole.Provider ||
      role.role_id === UserRole.CareNavigator
    ) {
      calls.unshift(
        call(fetchOrganizations),
        call(fetchGroups, []),
        put(Actions.fetchProviders(null, null, filterRoots.fetchFilters))
      );
    } else {
      calls.unshift(
        put(Actions.fetchProviders(null, null, filterRoots.fetchFilters))
      );
    }

    yield all(calls);
  } catch (error) {
    yield put(Actions.setParticipantsErrors(`[fetchFilters error]: ${error}`));
  } finally {
    yield put(
      Actions.setFiltersLoading({
        [filtersKeys.categories]: false,
        [filtersKeys.status]: false,
      })
    );
  }
}

function* setSelectedFilters(action) {
  const selectedFilters = yield select(Selectors.getSelectedFilters);
  const selectedNavigation = yield select(Selectors.getNavigation);
  const filterValues = yield select(Selectors.getFilters);
  const currentSearch = yield select(Selectors.getSearch);
  const filtersLoaders = yield select(Selectors.getFiltersIsLoading);
  const prevParams = yield select(Selectors.getPrevParams);
  const selectedView = yield select(Selectors.getSelectedView);
  const participants = yield select(Selectors.getParticipants);

  const { filters, search, from } = action;

  let calls = [];
  const filterKey = Object.keys(filters)[0];
  let filtersToSet = {};

  if (Object.keys(filters).length > 1) {
    filtersToSet = selectedFilters.merge(filters);
  } else {
    filtersToSet = selectedFilters.merge({
      [filterKey]: filters[filterKey],
    });
    if (
      from === filterRoots.filters &&
      selectedView &&
      selectedView?.value !== viewsDefaults.first
    ) {
      yield put(Actions.setViewAsSelected(viewsDefaults.default));
    }
  }

  if (filterKey === filtersKeys.organizations) {
    if (from === filterRoots.filters) {
      yield put(Actions.setSearchSuccess(''));
    }

    const organizationIdGroup = flatSelectedElementsToIds(filters[filterKey]);
    calls = [
      call(fetchGroups, organizationIdGroup, from),
      put(Actions.fetchProviders(null, organizationIdGroup, from)),
    ];
  }

  if (filterKey === filtersKeys.groups) {
    if (from === filterRoots.filters) {
      yield put(Actions.setSearchSuccess(''));
    }

    const organizationIdGroup = flatSelectedElementsToIds(
      selectedFilters[filtersKeys.organizations]
    );

    const groupId = flatSelectedElementsToIds(filters[filterKey]);
    calls = [put(Actions.fetchProviders(groupId, organizationIdGroup, from))];
  }

  if (
    currentSearch &&
    currentSearch.length > 0 &&
    from === filterRoots.search
  ) {
    yield put(Actions.setViewAsSelected(viewsDefaults.default));
    calls = [
      yield put(
        Actions.setSelectedFilters(
          {
            [filtersKeys.groups]: [],
            [filtersKeys.status]: filterValues[filtersKeys.status][0],
            [filtersKeys.categories]: [],
            [filtersKeys.providers]: [],
            [filtersKeys.organizations]: [],
          },
          from === filterRoots.search ? currentSearch : search,
          filterRoots.sagas
        )
      ),
    ];
  }

  yield put(
    Actions.setNavigation({
      ...selectedNavigation,
      currentPage: 1,
    })
  );

  if (from !== filterRoots.search) {
    yield put(
      Actions.setSelectedFiltersSuccess(
        {
          ...filters,
          ...filtersToSet,
        },
        search
      )
    );
  }

  yield all(calls);

  const categoryChangeOnSearch =
    JSON.stringify(filtersToSet[filtersKeys.categories]) !==
    JSON.stringify(selectedFilters[filtersKeys.categories]);

  const statusChangeOnSearch =
    JSON.stringify(filtersToSet[filtersKeys.status]) !==
    JSON.stringify(selectedFilters[filtersKeys.status]);

  if (
    from === filterRoots.setView ||
    (!Object.values(filtersLoaders).find((f) => f) &&
      JSON.stringify(filtersToSet) !== prevParams &&
      search !== prevParams) ||
    (search === prevParams &&
      (categoryChangeOnSearch || statusChangeOnSearch)) ||
    participants === null
  ) {
    yield put(Actions.fetchParticipants(filtersToSet, search));
  }
}

function* fetchViews(action) {
  const { section } = action;

  yield put(Actions.setViewsLoading(true));
  try {
    const response = yield call(getUserFiltersView, section);
    const views = response.userFilters.map((item) => ({
      value: item.id,
      label: item.name,
      filters: item.filters,
      key: `views-${item.id}`,
    }));

    yield put(
      Actions.fetchViewsSuccess([
        {
          value: viewsDefaults.first,
          label: 'Default View',
          key: 'views-0',
        },
        ...views,
      ])
    );
  } catch (error) {
    yield put(Actions.setParticipantsErrors(`[fetchViews error]: ${error}`));
  } finally {
    yield put(Actions.setViewsLoading(false));
  }
}

function* createView(action) {
  const { filters, name } = action;
  const views = yield select(Selectors.getViews);

  yield put(Actions.setViewsLoading(true));

  const repeatedView = views.find((view) => view.label === name.trim());

  if (repeatedView) {
    yield Swal.fire(
      'Warning',
      `The name you want to add is repeated`,
      'warning'
    );

    yield put(Actions.setViewsLoading(false));
    return;
  }

  try {
    yield call(
      createUserFiltersView,
      sections.participants,
      filters,
      name.trim()
    );
    Swal.fire('Success', `Views was added successfully`, 'success');
    yield put(Actions.fetchViews(sections.participants));
    yield put(Actions.setViewsLoading(false));
  } catch (error) {
    Swal.fire('Error', `Views can't be added correctly`, 'error');
    yield put(Actions.setParticipantsErrors(`[createView error]: ${error}`));
    yield put(Actions.setViewsLoading(false));
  }
}

function* deleteView(action) {
  const { id } = action;
  const selectedView = yield select(Selectors.getSelectedView);
  yield put(Actions.setViewsLoading(true));

  try {
    yield call(deleteUserFiltersView, id);

    Swal.fire('Success', `Views was removed successfully`, 'success');
    yield put(Actions.fetchViews(sections.participants));

    if (selectedView && selectedView.value === id) {
      yield put(Actions.setViewAsSelected(viewsDefaults.default));
    }
  } catch (error) {
    Swal.fire('Error', `Views can't be removed correctly`, 'error');
    yield put(Actions.setParticipantsErrors(`[createView error]: ${error}`));
    yield put(Actions.setViewsLoading(false));
  }
}

function* setViewAsSelected(action) {
  const { id } = action;
  const views = yield select(Selectors.getViews);

  const selectedFilters = yield select(Selectors.getSelectedFilters);
  const filters = yield select(Selectors.getFilters);

  let calls = [];
  let organizationIdGroup = null;
  let groupIdGroup = null;

  if (views === null) {
    return;
  }

  if (id === viewsDefaults.default) {
    yield put(Actions.setViewAsSelectedSuccess(views[0]));
    return;
  }

  const newFilters = Immutable.asMutable(
    views?.find((item) => item.value === id),
    { deep: true }
  );

  if (id === viewsDefaults.first) {
    const role = yield select(UserSelectors.getUserRole);
    const currentProvider = {
      value: role.user_id,
      key: `providers-${role.user_id}`,
    };

    let organizationDefault = [];

    if (filters[filtersKeys.organizations].length === 1) {
      organizationDefault = filters[filtersKeys.organizations];
    }

    const defaultFilters = {
      [filtersKeys.organizations]: organizationDefault,
      [filtersKeys.groups]: [],
      [filtersKeys.providers]: [currentProvider],
      [filtersKeys.categories]: [],
      [filtersKeys.status]: null,
    };
    yield put(Actions.setSelectedFiltersSuccess(defaultFilters));
    yield put(
      Actions.setSelectedFilters(defaultFilters, null, filterRoots.setView)
    );
    yield put(Actions.setViewAsSelectedSuccess(views[0]));
    return;
  }

  yield put(
    Actions.setFiltersLoading({
      [filtersKeys.groups]: true,
      [filtersKeys.categories]: true,
      [filtersKeys.providers]: true,
      [filtersKeys.organizations]: true,
      [filtersKeys.status]: true,
    })
  );

  try {
    if (
      JSON.stringify(newFilters.filters[filtersKeys.organizations]) !==
      JSON.stringify(selectedFilters[filtersKeys.organizations])
    ) {
      // FIXME: this is a workaround to avoid the error, because at the firs iteration we use single element instead of array.
      newFilters.filters[filtersKeys.organizations] = removeAllSelectedElements(
        Array.isArray(newFilters.filters[filtersKeys.organizations])
          ? newFilters.filters[filtersKeys.organizations]
          : [newFilters.filters[filtersKeys.organizations]]
      );
      organizationIdGroup = newFilters.filters[filtersKeys.organizations] ?? [];
      organizationIdGroup = flatSelectedElementsToIds(organizationIdGroup);

      calls = [...calls, call(fetchGroups, organizationIdGroup)];
    }

    if (
      JSON.stringify(newFilters.filters[filtersKeys.groups]) !==
      JSON.stringify(selectedFilters[filtersKeys.groups])
    ) {
      // FIXME: this is a workaround to avoid the error, because at the firs iteration we use single element instead of array.
      newFilters.filters[filtersKeys.groups] = removeAllSelectedElements(
        Array.isArray(newFilters.filters[filtersKeys.groups])
          ? newFilters.filters[filtersKeys.groups]
          : [newFilters.filters[filtersKeys.groups]]
      );
      groupIdGroup = newFilters.filters[filtersKeys.groups] ?? [];
      groupIdGroup = flatSelectedElementsToIds(groupIdGroup);

      calls = [
        ...calls,
        put(
          Actions.fetchProviders(
            groupIdGroup,
            organizationIdGroup,
            filterRoots.setView
          )
        ),
      ];
    }

    yield all(calls);
    yield put(Actions.setSelectedFiltersSuccess(Immutable(newFilters).filters));
    yield put(
      Actions.setSelectedFilters(
        Immutable(newFilters).filters,
        null,
        filterRoots.setView
      )
    );
    yield put(Actions.setViewAsSelectedSuccess(Immutable(newFilters)));
  } catch (error) {
    yield put(
      Actions.setParticipantsErrors(`[setViewAsSelected error]: ${error}`)
    );
  } finally {
    yield put(
      Actions.setFiltersLoading({
        [filtersKeys.groups]: false,
        [filtersKeys.categories]: false,
        [filtersKeys.providers]: false,
        [filtersKeys.organizations]: false,
        [filtersKeys.status]: false,
      })
    );
  }
}

function* setSearch(action) {
  const { search } = action;
  const role = yield select(UserSelectors.getUserRole);
  const selectedView = yield select(Selectors.getSelectedView);

  try {
    yield put(
      Actions.setFiltersLoading({
        [filtersKeys.groups]: true,
        [filtersKeys.categories]: true,
        [filtersKeys.providers]: true,
        [filtersKeys.organizations]: true,
        [filtersKeys.status]: true,
      })
    );
    const defaultFilters = {
      [filtersKeys.organizations]: [],
      [filtersKeys.groups]: [],
      [filtersKeys.providers]: [],
      [filtersKeys.categories]: [],
      [filtersKeys.status]: null,
    };
    yield put(Actions.setSelectedFiltersSuccess(defaultFilters));
    const calls = [];

    if (selectedView && selectedView?.value !== viewsDefaults.first) {
      yield put(Actions.setViewAsSelected(viewsDefaults.default));
    }

    if (
      role.role_id === UserRole.Provider ||
      role.role_id === UserRole.CareNavigator
    ) {
      calls.unshift(
        call(fetchGroups, [], filterRoots.search),
        put(Actions.fetchProviders(null, null, filterRoots.search))
      );
    } else {
      calls.unshift(
        put(Actions.fetchProviders(null, null, filterRoots.search))
      );
    }

    yield put(Actions.setSearchSuccess(search));
    yield all(calls);
    yield put(Actions.fetchParticipants(defaultFilters, search));
  } catch (error) {
    yield put(Actions.setParticipantsErrors(`[setSearch error]: ${error}`));
  } finally {
    yield put(
      Actions.setFiltersLoading({
        [filtersKeys.groups]: false,
        [filtersKeys.categories]: false,
        [filtersKeys.providers]: false,
        [filtersKeys.organizations]: false,
        [filtersKeys.status]: false,
      })
    );
  }
}

function* updateParticipantsListAfterUpdate() {
  const filtersSelected = yield select(Selectors.getSelectedFilters);
  const search = yield select(Selectors.getSearch);

  try {
    yield put(Actions.setPrevParams(''));
    yield put(Actions.fetchParticipants(filtersSelected, search));
  } catch (error) {
    yield put(
      Actions.setParticipantsErrors(
        `[updateParticipantsListAfterUpdate error]: ${error}`
      )
    );
  }
}

export default () => ({
  [Types.FETCH_PARTICIPANTS]: fetchParticipants,
  [Types.FETCH_FILTERS]: fetchFilters,
  [Types.FETCH_PROVIDERS]: fetchProviders,
  [Types.SET_SELECTED_FILTERS]: setSelectedFilters,
  [Types.FETCH_VIEWS]: fetchViews,
  [Types.CREATE_VIEW]: createView,
  [Types.DELETE_VIEW]: deleteView,
  [Types.SET_VIEW_AS_SELECTED]: setViewAsSelected,
  [Types.SET_SEARCH]: setSearch,
  [Types.UPDATE_PARTICIPANTS_LIST_AFTER_UPDATE]:
    updateParticipantsListAfterUpdate,
});
