import {call, put, putResolve, select} from 'redux-saga/effects';
import moment from 'moment';
import {
  addParticipantFile,
  addParticipantNote,
  assignLevel,
  getAllowParticipant,
  getAssessmentAnswers,
  getParticipantAssessments,
  getParticipantFiles,
  getParticipantNotes,
  getParticipantOverview,
  getParticipantProgress,
  getParticipantTimeTrackNotes,
  neededAssessments,
} from 'services/api/participant';
import {getWeekDates} from 'helpers/utcHelper';
import {createManualAssessment, getAssessmentSchedule,} from 'services/api/assessment';
import {getProgramSessions, getProgramsExtended} from 'services/api/program';
import Swal from 'sweetalert2';
import {ProviderActions} from 'store/provider';
import {getAllDaysOnWeekByDate} from 'helpers/dateHelpers';
import {ParticipantsActions} from 'store/participants';
import {formatAssessmentData} from 'helpers/assessmentHelper';
import {
  ParticipantActions as Actions,
  ParticipantSelectors as Selectors,
  ParticipantTypes as Types,
} from './participantReducer';

function* fetchParticipant({ participantId }) {
  yield put(Actions.resetParticipant());
  yield put(Actions.setParticipantLoading(true));
  const { currentDate } = yield select(Selectors.getProgressDates);

  try {
    yield call(getAllowParticipant, participantId);
    yield put(Actions.setRedirectToError(false));
  } catch (error) {
    yield put(Actions.setRedirectToError(true));
  }

  try {
    const participant = yield call(getParticipantOverview, participantId);

    yield put(Actions.fetchParticipantSuccess(participant));
    yield put(Actions.fetchPrograms(participant.id));
    yield put(Actions.fetchAssessments(participant.id));
    yield put(ProviderActions.fetchChatUrl(participant.id));
    yield put(Actions.fetchNotes(participant.id));
    yield put(Actions.fetchFiles(participant.id));
    yield put(Actions.setCurrentDate(participant.id, currentDate));
  } catch (e) {
    yield put(
      Actions.setParticipantErrors({
        error: e,
        notParticipant: true,
        message: '[getParticipantOverview]: problem getting data',
      })
    );
  } finally {
    yield put(Actions.setParticipantLoading(false));
  }
}

function* refreshParticipant({ participantId }) {
  yield put(Actions.setRefreshParticipant(true));

  try {
    const response = yield call(getParticipantOverview, participantId);

    yield putResolve(
      Actions.fetchParticipantSuccess({
        ...response,
        id: participantId,
      })
    );

    yield put(ParticipantsActions.updateParticipantsListAfterUpdate());
  } catch (e) {
    yield put(
      Actions.setParticipantErrors({
        error: e,
        notParticipant: true,
        message: '[getParticipantOverview]: problem getting data',
      })
    );
  } finally {
    yield put(Actions.setRefreshParticipant(false));
  }
}

function* fetchPrograms({ participantId }) {
  if (participantId) {
    yield put(Actions.setProgramLoading(true));

    try {
      const response = yield call(getProgramsExtended, participantId);

      yield put(Actions.fetchProgramsSuccess(response));
      const program = response.find((p) => p.current);

      yield put(Actions.setCurrentProgram(program));
      yield put(Actions.setCurrentLevel(program.currents));
    } catch (e) {
      yield put(Actions.setCurrentProgram(null));
      yield put(Actions.fetchProgramsSuccess([]));
      yield put(
        Actions.setParticipantErrors({
          error: e,
          message: '[getProgramsExtended]: problem getting data',
        })
      );
    } finally {
      yield put(Actions.setProgramLoading(false));
    }
  } else {
    yield put(
      Actions.setParticipantErrors({
        error: true,
        message: '[getProgramsExtended]: not participant id found',
      })
    );
  }
}

function* setAssignLevel({ level, phase }) {
  const participant = yield select(Selectors.getParticipant);

  if (participant?.id) {
    yield put(Actions.setAssingLevelLoading(true));

    try {
      yield call(assignLevel, participant.id, level.id);

      Swal.fire({
        title: 'Level changed correctly',
        text: '',
        icon: 'success',
      });
      yield put(
        Actions.setCurrentLevel({
          level,
          phase,
        })
      );
      yield put(Actions.setAssingLevelOk(true));
    } catch (e) {
      yield put(Actions.setPreviousLevel());
      yield put(
        Actions.setParticipantErrors({
          error: e,
          message: '[assignLevel]: problem assigning level',
        })
      );
      Swal.fire({
        title: 'Failed to change the level',
        text: 'Try again later',
        icon: 'error',
      });
    } finally {
      yield put(Actions.setAssingLevelLoading(false));
    }
  }
}

function* fetchAssessments({ participantId }) {
  if (participantId) {
    yield put(Actions.setAssessmentsLoading(true));

    try {
      const responseNeeded = yield call(neededAssessments, participantId);
      const data = {
        needed: responseNeeded.needed,
        url: responseNeeded.needed ? responseNeeded.url : null,
      };

      const { assessments, nextSchedule } = yield call(
        getParticipantAssessments,
        participantId
      );
      data.data = assessments;
      yield put(Actions.setCanHaveManualAssessment(!data.needed));
      yield put(
        Actions.fetchAssessmentsSuccess(
          formatAssessmentData(data),
          nextSchedule
        )
      );
    } catch (error) {
      yield put(Actions.fetchAssessmentsSuccess(null, null));
      yield put(
        Actions.setParticipantErrors({
          error,
          message: '[getNeedAssessment, getAssessments]: problem getting data',
        })
      );
    } finally {
      yield put(Actions.setAssessmentsLoading(false));
    }
  } else {
    yield put(
      Actions.setParticipantErrors({
        error: true,
        message:
          '[getNeedAssessment, getAssessments]: not participant id found',
      })
    );
  }
}

function* fetchAssessmentsAnswers({ participantId }) {
  if (participantId) {
    yield put(Actions.setAssessmentAnswersLoading(true));

    try {
      const response = yield call(getAssessmentAnswers, participantId);

      yield put(Actions.fetchAssessmentAnswersSuccess(response));
    } catch (error) {
      yield put(
        Actions.setParticipantErrors({
          error,
          message: '[getAssessmentAnswers]: problem getting data',
        })
      );
    } finally {
      yield put(Actions.setAssessmentAnswersLoading(false));
    }
  } else {
    yield put(
      Actions.setParticipantErrors({
        error: true,
        message: '[getAssessmentAnswers]: not participant id found',
      })
    );
  }
}

function* fetchNotes({ participantId }) {
  if (participantId) {
    yield put(Actions.setNotesLoading(true));
    const response = {
      notes: [],
      timeTrackerNotes: [],
    };

    try {
      const normalNotes = yield call(getParticipantNotes, participantId);

      response.notes = normalNotes;
    } catch (err) {
      yield put(
        Actions.setParticipantErrors({
          error: err,
          message: '[getParticipantNotes]: error getting notes',
        })
      );
    }

    try {
      const timeTrackerNotes = yield call(
        getParticipantTimeTrackNotes,
        participantId
      );

      response.timeTrackerNotes = timeTrackerNotes;
    } catch (err) {
      yield put(
        Actions.setParticipantErrors({
          error: err,
          message: '[getParticipantTimeTrackNotes]: error getting notes',
        })
      );
    }

    yield put(Actions.fetchNotesSuccess(response));
    yield put(Actions.setNotesLoading(false));
  } else {
    yield put(
      Actions.setParticipantErrors({
        error: true,
        message:
          '[getParticipantNotes, getParticipantTimeTrackNotes]: not participant id found',
      })
    );
  }
}

function* setNotes({ note }) {
  yield put(Actions.setNotesModalLoading(true));
  const participant = yield select(Selectors.getParticipant);

  if (participant && participant?.id) {
    try {
      yield call(addParticipantNote, { ...note, user_id: participant.id });
      Swal.fire('Added', `The note was saved successfully`, 'success');
      yield put(Actions.setNotesSuccess(true));
      yield put(Actions.fetchNotes(participant.id));
    } catch (err) {
      Swal.fire(
        'Oops!',
        `Limber wasn't able to add the note, please try again`,
        'error'
      );
      yield put(
        Actions.setParticipantErrors({
          error: true,
          message: '[addParticipantNote]: error getting data',
        })
      );
    }
  } else {
    yield put(
      Actions.setParticipantErrors({
        error: true,
        message: '[addParticipantNote]: not participant id found',
      })
    );
  }
  yield put(Actions.setNotesModalLoading(false));
}

function* fetchFiles({ participantId }) {
  yield put(Actions.fetchFilesSuccess(null));
  try {
    yield put(Actions.setFilesLoading(true));
    const response = yield call(getParticipantFiles, participantId);

    yield put(Actions.fetchFilesSuccess({ data: response }));
  } catch (err) {
    yield put(
      Actions.setParticipantErrors({
        error: err,
        message: '[getParticipantFiles]: error getting files',
      })
    );
  } finally {
    yield put(Actions.setFilesLoading(false));
  }
}

function* fetchProgress({ participantId, date }) {
  if (participantId) {
    try {
      yield put(Actions.setProgressLoading(true));
      const [startDate, endDate] = getWeekDates(date);
      const shouldUseUTCImplementation =
        moment(process.env.REACT_APP_IS_UTC_IMPLEMENT).diff(
          startDate,
          'days'
        ) <= 0;

      const response = yield call(
        getParticipantProgress,
        participantId,
        date
      );

      if (shouldUseUTCImplementation) {
        const { data } = yield call(getProgramSessions, {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          participantId: Number(participantId),
        });

        response.completed_sessions = data
          ? data.map((session) => ({
              date: session.created_at,
              isUtc: true,
            }))
          : [];
      }

      yield put(Actions.fetchProgressSuccess(response));
    } catch (err) {
      yield put(Actions.fetchProgressSuccess(null));
      yield put(
        Actions.setParticipantErrors({
          error: err,
          message: '[getParticipantProgress]: error getting progress',
        })
      );
    } finally {
      yield put(Actions.setProgressLoading(false));
    }
  } else {
    yield put(Actions.setProgressLoading(false));
    yield put(
      Actions.setParticipantErrors({
        error: true,
        message: '[getParticipantProgress]: not participant id found',
      })
    );
  }
}

function* setCurrentDate({ participantId, date }) {
  const dates = getAllDaysOnWeekByDate(date);

  yield put(
    Actions.setCurrentDateSuccess({
      dates,
      currentDate: date,
    })
  );
  yield put(Actions.fetchProgress(participantId, date));
}

function* setFile({ participantId, file }) {
  if (participantId) {
    try {
      yield put(Actions.setFilesLoading(true));
      yield call(addParticipantFile, participantId, file.name, file);

      Swal.fire({
        title: 'File saved successfully',
        icon: 'success',
      });
      yield put(Actions.fetchFiles(participantId));
    } catch (error) {
      Swal.fire({
        title:
          'Error while trying to save the file, try again in a few minutes',
        icon: 'error',
      });
      yield put(
        Actions.setParticipantErrors({
          error,
          message: '[addParticipantFile]: error getting data',
        })
      );
      yield put(Actions.setFilesLoading(false));
    }
  } else {
    yield put(
      Actions.setParticipantErrors({
        error: true,
        message: '[addParticipantFile]: not participant id found',
      })
    );
  }
}

function* fetchAssessmentsSchedule(action) {
  yield put(Actions.setAssessmentScheduleLoading(true));
  const { participantId } = action;

  try {
    const response = yield call(getAssessmentSchedule, participantId);
    yield put(Actions.fetchAssessmentScheduleSuccess(response.data));
  } catch (error) {
    yield put(
      Actions.setParticipantErrors({
        error,
        message: '[getAssessmentSchedule]: problem getting schedule',
      })
    );
  } finally {
    yield put(Actions.setAssessmentScheduleLoading(false));
  }
}

function* setManualAssessment(action) {
  yield put(Actions.setManualAssessmentLoading(true));
  const { participantId } = action;

  try {
    yield call(createManualAssessment, participantId);
    Swal.fire('Added', `Reassessment added Successfully`, 'success');
    yield put(Actions.fetchAssessments(participantId));
  } catch (error) {
    Swal.fire('Oops!', `Fail to add Reassessment`, 'error');
    yield put(
      Actions.setParticipantErrors({
        error,
        message: '[createManualAssessment]: problem setting manual',
      })
    );
  } finally {
    yield put(Actions.setManualAssessmentLoading(false));
  }
}

export default () => ({
  [Types.FETCH_PARTICIPANT]: fetchParticipant,
  [Types.REFRESH_PARTICIPANT]: refreshParticipant,
  [Types.FETCH_PROGRAMS]: fetchPrograms,
  [Types.SET_ASSIGN_LEVEL]: setAssignLevel,
  [Types.FETCH_ASSESSMENTS]: fetchAssessments,
  [Types.FETCH_ASSESSMENT_ANSWERS]: fetchAssessmentsAnswers,
  [Types.FETCH_NOTES]: fetchNotes,
  [Types.SET_NOTES]: setNotes,
  [Types.FETCH_FILES]: fetchFiles,
  [Types.FETCH_PROGRESS]: fetchProgress,
  [Types.SET_CURRENT_DATE]: setCurrentDate,
  [Types.SET_FILE]: setFile,
  [Types.FETCH_ASSESSMENT_SCHEDULE]: fetchAssessmentsSchedule,
  [Types.SET_MANUAL_ASSESSMENT]: setManualAssessment,
});
