import { exerciseDurationType } from 'constants/defaultValues';

export function formatHepText(printProgram) {
  const groups = JSON.parse(printProgram.builder_data);
  let text = `Date: ${new Date()}
    Prescribed by: ${printProgram.medicData.first_name} ${
    printProgram.medicData.last_name
  }
    Program Name: ${printProgram.name}
    Home Program Exercises:`;

  groups.forEach((group) => {
    const timesPerDay = group?.timesPerDay.value || group.timesPerDay;
    const timesPerWeek = group?.timesPerWeek.value || group.timesPerWeek;
    const sets = group?.sets.value || group.sets;
    const reps = group?.reps.value || group.reps;
    const hold = group?.hold.value || group.hold;
    const time = group?.time.value || group.time;

    text += `
        • ${group.name} -`;
    if (group.exercises.length > 1) {
      text += ` ${group.leftRight.label} -`;
    }
    text += ` ${timesPerDay} x daily - ${timesPerWeek} x weekly - ${sets} sets - `;
    if (group.durationType === exerciseDurationType.reps) {
      text += `${reps} reps`;
    } else if (group.durationType === exerciseDurationType.repsAndHold) {
      text += `${reps} reps - ${hold} sec hold`;
    } else {
      text += `${time} seconds`;
    }
  });

  return text;
}

export function calculateDuration(
  videoDuration,
  repetitionsInVideo = 4,
  holdTime = 0,
  reps = 1
) {
  let duration = 0;
  if (holdTime > 0) {
    duration = holdTime;
  } else if (reps > 0) {
    const oneExerciseDuration = videoDuration / repetitionsInVideo;
    // ADD rep buffer ( reps + 1 ) for 1 rep buffer
    duration = reps * oneExerciseDuration;
    if (duration < 15) {
      duration = 15;
    }
  } else {
    duration = 15;
  }
  return duration;
}

export const formatBuilderData = ({ defaultBuilderData, exercise }) => {
  const data = { ...exercise };
  if (exercise?.favorites) {
    delete data.favorites;
  }

  return { ...data, ...defaultBuilderData, groupId: data.id };
};

export const prepareBuilderDataToSave = (data) => {
  const result = data.map(
    ({
      id,
      groupId,
      image,
      // eslint-disable-next-line camelcase
      image_url,
      leftRight,
      defaultAlias,
      timesPerWeek,
      timesPerDay,
      reps,
      sets,
      time,
      seconds,
      minutes,
      hold,
      durationType,
      order,
      exercises,
      name,
    }) => {
      const groupIdValue = groupId ?? exercises[0].group_id;
      const repsValue =
        typeof reps === 'object' && reps != null ? Number(reps.value) : reps;
      const timeValue =
        typeof time === 'object' && time != null ? Number(time.value) : time;
      const setsValue =
        typeof sets === 'object' && sets != null ? Number(sets.value) : sets;
      const holdValue =
        typeof hold === 'object' && hold != null ? Number(hold.value) : hold;
      const timesPerWeekValue =
        typeof timesPerWeek === 'object' && timesPerWeek != null
          ? Number(timesPerWeek.value)
          : timesPerWeek;
      const timesPerDayValue =
        typeof timesPerDay === 'object' && timesPerDay != null
          ? Number(timesPerDay.value)
          : timesPerDay;

      return {
        id,
        groupId: groupIdValue,
        image,
        image_url,
        leftRight,
        defaultAlias,
        timesPerWeek: timesPerWeekValue,
        timesPerDay: timesPerDayValue,
        reps: repsValue,
        sets: setsValue,
        time: timeValue,
        seconds,
        minutes,
        hold: holdValue,
        durationType,
        order,
        exercises,
        name,
      };
    }
  );

  return result;
};

export const prepareExercisesToSave = (data) => {
  const result = data.map((exercise) => {
    const config = {};
    let note = exercise.note ?? null;

    switch (exercise.durationType) {
      case 1:
        config.reps =
          typeof exercise.reps === 'object'
            ? Number(exercise.reps.value)
            : Number(exercise.reps);
        break;
      case 2:
        config.time =
          typeof exercise.time === 'object'
            ? Number(exercise.time.value)
            : Number(exercise.time);
        break;
      case 3:
        config.holdRep = {
          time:
            typeof exercise.hold === 'object'
              ? Number(exercise.hold.value)
              : Number(exercise.hold),
          reps:
            typeof exercise.reps === 'object'
              ? Number(exercise.reps.value)
              : Number(exercise.reps),
        };

        if (!note) {
          note = `Hold for ${config.holdRep.time} seconds`;
        }
        break;
      default:
        throw Error(
          '[prepareExercisesToSave] something whent wrong creating hep'
        );
    }

    if (exercise.defaultAlias) {
      config.alias = exercise.defaultAlias;
    }

    let exerciseIds = exercise.exercises.map(({ id }) => id);
    if (exercise.exercises.length > 1) {
      if (exercise.leftRight.value === '2') {
        exerciseIds = [exercise.exercises[1].id];
      } else if (exercise.leftRight.value === '3') {
        exerciseIds = [exercise.exercises[0].id];
      }
    }

    let exerciseId = exercise.groupId;
    if (!exercise.groupId) {
      exerciseId = exercise.exercises[0].group_id;
    }

    const timesPerWeek =
      typeof exercise.timesPerWeek === 'object'
        ? Number(exercise.timesPerWeek.value)
        : Number(exercise.timesPerWeek);

    const timesPerDay =
      typeof exercise.timesPerDay === 'object'
        ? Number(exercise.timesPerDay.value)
        : Number(exercise.timesPerDay);

    const sets =
      typeof exercise.sets === 'object'
        ? Number(exercise.sets.value)
        : Number(exercise.sets);

    return {
      id: Number(exerciseId),
      timesPerWeek,
      timesPerDay,
      sets,
      exerciseIds,
      order:
        exercise.order ?? data.findIndex(({ id }) => id === exercise.id) + 1,
      note,
      ...config,
    };
  });

  return result;
};

export const generateKey = () => Math.floor(Math.random() * 10000 + 1);
