import Immutable from 'seamless-immutable';

export const filters = Immutable({
  providers: 'PROVIDER',
  categories: 'CATEGORY',
  status: 'STATUS',
  groups: 'GROUP',
  organizations: 'ORGANIZATION',
});

export const sections = Immutable({
  participants: 'PARTICIPANT_LIST',
});
