/* eslint-disable import/prefer-default-export */
import axios from 'axios';

let savedRoles;

export const getRole = async (token = null, signal) => {
  if (savedRoles) {
    return Promise.resolve(savedRoles);
  }

  if (!token) return Promise.reject(new Error('No token given'));

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (signal) {
    config.signal = signal;
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_LIMBER_API_BASE_URL}/users/getrol`,
      config
    );
    // FIXME: all the verification process is kinda odd need to be refactor to add speed and consistance.
    savedRoles = [response.data];
    return Promise.resolve(savedRoles);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const resetRole = () => {
  savedRoles = null;
};

export const getSavedRole = () => {
  return savedRoles;
};
