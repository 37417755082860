import { select, call, put } from 'redux-saga/effects';
import {
  getExerciseFilters,
  getExercises,
  getExercisesAutoComplete,
} from 'services/api/builder';
import { filters as filterKeys } from 'constants/exercisesFilters';

import {
  ExercisesSelectors as Selectors,
  ExercisesActions as Actions,
  ExercisesTypes as Types,
} from './exercisesReducer';

function* fetchExercises() {
  const navigation = yield select(Selectors.getNavigation);
  const search = yield select(Selectors.getSearch);
  const filters = yield select(Selectors.getFilters);

  yield put(Actions.setExercisesLoading(true));

  try {
    const { rows, count, userId } = yield call(
      getExercises,
      search,
      navigation.currentPage,
      navigation.limit,
      filters[filterKeys.favoritesOnly],
      filters,
      filters[filterKeys.aliasOnly]
    );

    const totalPages = Math.ceil(count / navigation.limit);

    yield put(Actions.setNavigation({ totalPages }));

    const response = rows.map((r) => ({
      ...r,
      userId,
    }));

    yield put(Actions.fetchExercisesSuccess(response));
  } catch (error) {
    yield put(
      Actions.setExercisesErrors([`[fetchExcercises error]: ${error}`])
    );
  } finally {
    yield put(Actions.setExercisesLoading(false));
  }
}

function* fetchAutoComplete() {
  const search = yield select(Selectors.getSearchText);

  if (!search || search.length <= 1) {
    yield put(Actions.fetchAutoCompleteSuccess([]));
    return;
  }

  try {
    yield put(Actions.setAutoCompleteLoading(true));
    const { rows } = yield call(getExercisesAutoComplete, search);

    const data = rows.map((r) => ({ ...r, label: r.name }));

    yield put(Actions.fetchAutoCompleteSuccess(data));
  } catch (error) {
    yield put(
      Actions.setAutoCompleteErrors([`[fetchExcercises error]: ${error}`])
    );
  } finally {
    yield put(Actions.setAutoCompleteLoading(false));
  }
}

function* fetchExercisesFilters() {
  yield put(Actions.setExercisesFiltersLoading(true));
  try {
    const results = yield call(getExerciseFilters);
    yield put(Actions.fetchExercisesFiltersSuccess(results));
  } catch (error) {
    yield put(
      Actions.setExercisesFiltersErrors([
        `[fetchExcercisesFilters error]: ${error}`,
      ])
    );
  } finally {
    yield put(Actions.setExercisesFiltersLoading(false));
  }
}

export default () => ({
  [Types.FETCH_EXERCISES]: fetchExercises,
  [Types.FETCH_AUTO_COMPLETE]: fetchAutoComplete,
  [Types.FETCH_EXERCISES_FILTERS]: fetchExercisesFilters,
});
