import { combineReducers } from 'redux';
import { resettableReducer } from 'reduxsauce';
import actionWatchReducer from 'redux-action-watch/lib/reducer';
import settings from './settings/reducer';
import menu from './menu/reducer';
import { ParticipantReducer } from './participant';
import { ParticipantsReducer } from './participants';
import { EducationsReducer } from './educations';
import { ProviderReducer } from './provider';
import { UserReducer } from './user';
import { ExercisesReducer } from './exercises';
import { HepReducer } from './hep';
import { ProtocolsReducer } from './protocols';
import { RtmReducer } from './rtm';
import { DashboardsReducer } from './dashboards';
import { ParticipantFlagsReducer } from './participant-flags';
import { featureFlagsReducer } from './feature-flags';

const resettable = resettableReducer('RESET');

export default combineReducers({
  menu,
  settings,
  participant: resettable(ParticipantReducer),
  participants: resettable(ParticipantsReducer),
  educations: resettable(EducationsReducer),
  provider: resettable(ProviderReducer),
  exercises: resettable(ExercisesReducer),
  user: resettable(UserReducer),
  hep: resettable(HepReducer),
  protocols: resettable(ProtocolsReducer),
  rtm: resettable(RtmReducer),
  dashboards: resettable(DashboardsReducer),
  participantFlags: resettable(ParticipantFlagsReducer),
  featureFlags: resettable(featureFlagsReducer),
  // watcher - Must be last
  watcher: actionWatchReducer,
});
