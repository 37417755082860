import { all } from 'redux-saga/effects';
import { participantBinds } from './participant';
import { participantsBinds } from './participants';
import { educationsBinds } from './educations';
import { providerBinds } from './provider';
import { userBinds } from './user';
import { excercisesBinds } from './exercises';
import { HepBinds } from './hep';
import { ProtocolsBinds } from './protocols';
import { RtmBinds } from './rtm';
import { DashboardsBinds } from './dashboards';
import { participantFlagsBinds } from './participant-flags';
import { featureFlagBinds } from './feature-flags';

export default function* rootSaga() {
  yield all([
    ...participantBinds,
    ...providerBinds,
    ...userBinds,
    ...participantsBinds,
    ...educationsBinds,
    ...excercisesBinds,
    ...HepBinds,
    ...ProtocolsBinds,
    ...RtmBinds,
    ...DashboardsBinds,
    ...participantFlagsBinds,
    ...featureFlagBinds,
  ]);
}
