import axios from 'axios';
import { axiosInstance } from './base';
import moment from 'moment';

function getProgramPDF(userId, layout) {
  const { headers } = axiosInstance.defaults;

  return axios.get(
    `${process.env.REACT_APP_LIMBER_PDF_API}/hep-pdfs/${userId}`,
    {
      params: {
        layout,
      },
      headers: {
        Authorization: headers.common.Authorization,
      },
    }
  );
}

function downloadPDF(file, participantLastName, programName) {
  return axios.get(file, {
      headers: {
        "Content-Type": "application/octet-stream"
      },
      responseType: "blob"
    })
    .then(response => {
      const a = document.createElement("a");
      const url = window.URL.createObjectURL(response.data);
      a.href = url;
      const today = moment().format("YYYYMMDD");
      a.download = `${participantLastName}-${programName}-${today}.pdf`;
      a.click();
    });
}

// eslint-disable-next-line import/prefer-default-export
export { getProgramPDF, downloadPDF };
