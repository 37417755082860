/* eslint-disable camelcase */
import { sortOrders } from 'constants/defaultValues';
import { createQueryParamsAsString } from 'helpers/requestHelpers';
import moment from 'moment';
import { getRequest, postRequest, putRequest } from './base';

export const getParticipants2 = async (
  search = '',
  orgId,
  groupId,
  providerId,
  offset,
  limit,
  unassigned
) => {
  return getRequest(
    `participantsv2?search=${search ? encodeURIComponent(search) : ''}${
      orgId ? `&orgId=${orgId}` : ''
    }${groupId ? `&groupId=${groupId}` : ''}${
      providerId ? `&providerId=${providerId}` : ''
    }${limit > 0 ? `&limit=${limit}` : ''}${
      offset > 0 ? `&offset=${offset}` : ''
    }&unassigned=${unassigned}`
  ).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const createUser = async (user) => {
  return postRequest('/user', user).then((response) => response.data);
};

export const createParticipant = async (participant) => {
  const toCreate = { ...participant };
  toCreate.role = 1;
  toCreate.active = 1;
  toCreate.date = new Date();
  return postRequest('/user', toCreate).then((response) => response.data);
};

export const neededAssessments = async (participant_id) => {
  return getRequest(`/assessment/url/${participant_id}`).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const editParticipant = async (info) => {
  return postRequest('users/edit', info).then((response) => response.data);
};

export const assignParticipantToProvider = async (
  participant_id,
  provider_id
) => {
  return postRequest('users/assigntomedic', {
    user_id: participant_id,
    medic_id: provider_id,
  }).then((response) => response.data);
};

export const assignParticipantToProgram = async (
  participant_id,
  program_id
) => {
  return postRequest('users/assignprogram', {
    user_id: participant_id,
    program_id,
    date: new Date(),
  }).then((response) => response.data);
};

export const getParticipantOverview = async (participant_id) => {
  return getRequest(`overview/${participant_id || ''}`).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getAllowParticipant = async (participantId, orgId) => {
  return getRequest(
    `participantOrg?participantId=${participantId}&orgSearch=${orgId}`
  ).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getParticipantProgress = async (participantId, date = null) => {
  return getRequest(
    `today/statsbyid?user_id=${participantId || ''}&date=${
      moment(date).toISOString()
    }`
  ).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getParticipantAssessments = async (participant_id) => {
  return getRequest(`assessments/getall/${participant_id || ''}`).then(
    (response) => {
      if (response.status === 200) return response.data;
      return Promise.reject(response.statusText);
    }
  );
};

export const getParticipantNotes = async (participant_id) => {
  return getRequest(`medicnotes/${participant_id}`).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getParticipantTimeTrackNotes = async (participant_id) => {
  return getRequest(`timetracking/${participant_id}`).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getParticipantTimeTrackerNotes = async (participant_id) => {
  return getRequest(`timetracking/${participant_id}`).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const addParticipantNote = async (data) => {
  return postRequest('medicnotes', data).then((response) => response.data);
};

export const saveTimetracker = async (data) => {
  return postRequest('timetracking', data).then((response) => response.data);
};

export const editTimetracker = async (data, id) => {
  return putRequest(`/timetrack/${id}`, data).then((response) => response.data);
};

export const addParticipantFile = async (participant_id, fileName, file) => {
  return new Promise((resolve, reject) => {
    postRequest('fileupload/presigned', {
      user_id: participant_id,
      file_name: fileName,
      contentType: file.type,
    })
      .then((response) => {
        const { data } = response;
        fetch(data.presigned_upload_url, {
          method: 'PUT',
          body: file,
        })
          .then((resp) => resp.data)
          .then(resolve)
          .catch(reject);
      })
      .catch(reject);
  });
};

export const getOrganizations = async () => {
  return getRequest(`organizations/getfirstlevel`).then((response) => {
    if (response.status === 200) return response.data;
    if (response.status === 204) return [];
    return Promise.reject(response.statusText);
  });
};

export const getGroups = async (orgId, signal) => {
  const config = {};

  if (signal) {
    config.signal = signal;
  }

  return getRequest(
    `organizations/getgroups${
      orgId && orgId.length > 0 ? `?orgId=${orgId}` : ''
    }`,
    config
  ).then((response) => {
    if (response.status === 200) return response.data;
    if (response.status === 204) return [];
    return Promise.reject(response.statusText);
  });
};

export const getProviders = (groupIds, orgIds, signal) => {
  const config = {};

  if (signal) {
    config.signal = signal;
  }

  const params = {
    groupId: groupIds,
    orgId: groupIds ? null : orgIds,
  };
  return getRequest(
    `/providers/getbyorg?${createQueryParamsAsString(params)}`,
    config
  ).then((response) => {
    if (response.status === 200) return response.data;
    if (response.status === 204) return [];
    return Promise.reject(response.statusText);
  });
};

export const getParticipantFiles = (participantId) => {
  return getRequest(`userfiles/${participantId}`).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getAssessmentAnswers = (participantId) => {
  return getRequest(`assessmentView/${participantId}`).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const assignLevel = (participantId, levelId) => {
  return postRequest('program/setLevel', {
    participantId,
    level: levelId,
  }).then((response) => response.data);
};

export const getProviders2 = (
  search = '',
  orgId,
  groupId,
  limit = 5,
  offset
) => {
  return getRequest(
    `providers?search=${search ? encodeURIComponent(search) : ''}${
      orgId ? `&orgId=${orgId}` : ''
    }${groupId ? `&groupId=${groupId}` : ''}${
      limit > 0 ? `&limit=${limit}` : ''
    }${offset > 0 ? `&offset=${offset}` : ''}`
  ).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getStatusOptions = (showHidden = false) => {
  return getRequest(`status?show_hidden=${showHidden}`).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getCategoryOptions = () => {
  return getRequest(`tags`).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getPrimaryInsuranceOptions = () => {
  return getRequest(`status`).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getProviderOptions = () => {
  return getRequest(`status`).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getTags = (qParams) => {
  return getRequest(`tags?${new URLSearchParams(qParams).toString()}`).then(
    (response) => {
      if (response.status === 200) return response.data;
      return Promise.reject(response.statusText);
    }
  );
};

export const getParticipantsList = ({
  search = '',
  orgIds,
  groupIds,
  providerIds,
  page,
  limit,
  unassigned,
  sort,
  categories,
  status,
  signal,
}) => {
  let queryParams = {
    orgId: orgIds,
    groupId: groupIds,
    providerId: providerIds,
    categories,
    status,
    page,
    unassigned,
    time: new Date().toISOString(),
    search: search ? encodeURIComponent(search) : '',
  };

  if (sort) {
    queryParams = {
      ...queryParams,
      orderByField: sort[0],
      orderType: sortOrders[sort[1]],
    };
  }

  if (limit > 0) {
    queryParams = {
      ...queryParams,
      limit,
    };
  }

  const config = {};

  if (signal) {
    config.signal = signal;
  }

  return getRequest(
    `participants/list?${createQueryParamsAsString(queryParams)}`,
    config
  ).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getParticipantFlagsList = async ({ id }) => {
  return getRequest(`participants/${id}/flags/`).then((response) => {
    if (response.status === 200) {
      const { flags } = response.data;

      return flags;
    }
    return Promise.reject(response.statusText);
  });
};

export const resolveParticipantFlagById = async (params) => {
  console.log('calling resolve participant flag', { params });
  const { id } = params;
  return putRequest(`participants/flags/${id}/resolve`).then((response) => {
    if (response.status === 200) {
      console.log('success resolved', { response });
      const { is_resolved } = response.data;
      return is_resolved;
    }
    return Promise.reject(response.statusText);
  });
};

export const getTimeTrackerNoteTypes = () => {
  return getRequest(`timetrack/types`).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getParticipantsPaginated = ({
  page = 1,
  limit = 50,
  sortBy = null, //STR
  sortOrder = 'desc',
  statusId = null, // INT
  orgId = null, // INT
  enabled = null, //BOOL
  search = '' //STR
}) => {
  return getRequest(`/participants/paginated?${createQueryParamsAsString({
    page, limit, sortBy, sortOrder, statusId, orgId, enabled, search
  })}`)
    .then(response => {
      if (response.status === 200) return response.data;
      return Promise.reject(response.statusText);
    })
};

export const deactivateParticipant = async (id) => {
  return putRequest(`/participant/${id}/deactivate`).then((response) => response.data);
};