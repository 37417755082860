/* eslint-disable import/prefer-default-export */
import { takeLatest } from 'redux-saga/effects';
import { getSagasBinds } from 'helpers/sagasHelper';
import { ExercisesTypes as Types } from './exercisesReducer';
import ExercisesSagas from './exercisesSagas';

const exercisesTemplate = {
  data: [
    {
      type: Types.FETCH_EXERCISES,
      action: takeLatest,
    },
    {
      type: Types.FETCH_AUTO_COMPLETE,
      action: takeLatest,
    },
    {
      type: Types.FETCH_EXERCISES_FILTERS,
      action: takeLatest,
    },
  ],
  sagas: ExercisesSagas,
};

export const excercisesBinds = getSagasBinds(exercisesTemplate);
